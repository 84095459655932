import { StaticImage } from 'gatsby-plugin-image';
import React, { ReactNode } from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { ITestComponentState } from './test-component-interface';
import BaseComponent from '../../base/base-component';
import LineChart from '../charts/line/line-chart';
import { componentScoreData } from '../../data/mock/component-score';

export default class TestComponent extends BaseComponent<
  any,
  ITestComponentState
> {
  public constructor(props) {
    super(props);

    this.state = {
      href: '',
    };
  }

  public componentDidMount(): void {
    this.setState({
      href: window?.location?.href,
    });
  }

  public render(): ReactNode {
    return (
      <div>
        <FacebookShareButton url={this.state.href}>
          <FacebookIcon />
        </FacebookShareButton>
        <TwitterShareButton url={this.state.href}>
          <TwitterIcon />
        </TwitterShareButton>
        <StaticImage
          src="../../images/icon.png"
          alt="text"
          width={50}
          height={50}
        />
        <div
          style={{ width: '800px', height: '500px' }}
          data-id="share"
          data-name="chart"
        >
          <LineChart
            data={componentScoreData}
            margin={{ top: 50, right: 160, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 70, max: 90 }}
            yFormat=" >-.2f"
            curve="linear"
            axisTop={null}
            useMesh={true}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              format: '.0f',
              legend: 'Year',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 20,
              tickRotation: 0,
              format: '.2s',
              legend: 'Amount',
              legendOffset: -50,
              legendPosition: 'middle',
            }}
            enableGridX={false}
            colors={(data): string => {
              return data.color;
            }}
            lineWidth={5}
            enablePoints={true}
            pointColor={{ theme: 'background' }}
            pointSize={10}
            pointBorderWidth={10}
            pointBorderColor={{ from: 'serieColor' }}
            enableArea={false}
            areaOpacity={0.05}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 136,
                translateY: 0,
                itemsSpacing: 2,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 12,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    );
  }
}
