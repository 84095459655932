import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import BaseComponent from '../base/base-component';
import Footer from '../components/footer/footer';
import TestComponent from '../components/test-component/test-component';
import img from '../images/share/test-page-chart.png';

export default class TestPage extends BaseComponent<any> {
  public render(): ReactNode {
    return (
      <div>
        <Helmet>
          <title>Test Page</title>
          <meta property="og:title" content="Test Page" />
          <meta property="og:description" content="Description Is Here" />
          <meta
            property="og:image"
            content={`${this.props.location.origin}${img}`}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:site" content="HamidrezaAmini7" />
          <meta property="twitter:creator" content="HamidrezaAmini7" />
          <meta property="twitter:title" content="Test Page" />
          <meta
            property="twitter:image:src"
            content={`${this.props.location.origin}${img}`}
          />
          <meta property="twitter:description" content="Description Is Here" />
        </Helmet>
        Welcome to gatsby
        <TestComponent />
        <div data-id="share" data-name="footer">
          <Footer
            menuItems={[
              { title: 'About', path: '/about' },
              { title: 'Read the Report', path: '/404' },
            ]}
          />
        </div>
      </div>
    );
  }
}
