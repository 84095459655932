import { Serie } from '@nivo/line';

export const componentScoreData: Array<Serie> = [
  {
    id: 'California',
    color: 'orange',
    data: [
      { x: '2012', y: 76 },
      { x: '2013', y: 72 },
      { x: '2014', y: 79 },
      { x: '2015', y: 80 },
      { x: '2016', y: 74 },
      { x: '2017', y: 72 },
      { x: '2018', y: 75 },
      { x: '2019', y: 77 },
      { x: '2020', y: 79 },
    ],
  },
  {
    id: 'Arizona',
    color: 'darkgreen',
    data: [
      { x: '2012', y: 86 },
      { x: '2013', y: 80 },
      { x: '2014', y: 72 },
      { x: '2015', y: 75 },
      { x: '2016', y: 79 },
      { x: '2017', y: 90 },
      { x: '2018', y: 83 },
      { x: '2019', y: 81 },
      { x: '2020', y: 86 },
    ],
  },
  {
    id: 'Chicago',
    color: 'lightblue',
    data: [
      { x: '2012', y: 75 },
      { x: '2013', y: 74 },
      { x: '2014', y: 75 },
      { x: '2015', y: 85 },
      { x: '2016', y: 84 },
      { x: '2017', y: 83 },
      { x: '2018', y: 89 },
      { x: '2019', y: 87 },
      { x: '2020', y: 79 },
    ],
  },
];
