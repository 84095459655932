import { ResponsiveLine, ResponsiveLineCanvas } from '@nivo/line';
import React, { ReactNode } from 'react';
import BaseComponent from '../../../base/base-component';
import { ILineChartProperties } from './line-chart-interface';

export default class LineChart extends BaseComponent<ILineChartProperties> {
  public render(): ReactNode {
    return <ResponsiveLine {...this.props} />;
  }
}
