import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import { Route } from '../../base/route-enum';
import { IFooterMenuItem, IFooterProperties } from './footer-interface';
import './footer.sass';

export default class Footer extends BaseComponent<IFooterProperties> {
  public render(): ReactNode {
    const { menuItems } = this.props;

    return (
      <div className="c-footer">
        <div className="c-footer__menu">
          {menuItems.map(
            (menuItem: IFooterMenuItem, index: number): ReactNode => {
              return (
                <Link
                  key={index}
                  to={menuItem.path}
                  className="c-footer__menu-item-link"
                >
                  {menuItem.title}
                </Link>
              );
            }
          )}
        </div>
        <div className="c-footer__info">
          <p>
            <Link to={Route.PRIVACY_POLICY}>Privacy Policy</Link>
          </p>
          <p>
            <Link to={Route.CONTACT}>Contact</Link>
          </p>
          <p>&copy; {new Date().getFullYear()} Unite America</p>
        </div>
      </div>
    );
  }
}
