export enum Route {
  HOME = '/',
  NOT_FOUND = '/404',
  ABOUT = '/about',
  READ_THE_REPORT = '/read-the-report',
  MEDIA = '/media',
  METHODOLOGY = '/methodology',
  TAKE_ACTION = '/take-action',
  PRIVACY_POLICY = '/privacy',
  CONTACT = '/contact',
}
